<template>
  <div class="scale-wrapper">
    <div>
      <item-scale
        v-model="itemValue"
        :availableValues="availableValues"
        :anchors="anchors"
        :readOnly="readOnly"
        :printView="printView"
        :label="label"
        :small="small"
      ></item-scale>
    </div>
  </div>
</template>

<script>
import itemScale from '@/components/forms/questionnaire/form-elements/item-scale.vue';

export default {
  props: {
    value: {
      type: Object,
      required: false,
      default: () => {}
    },

    readOnly: {
      type: Boolean,
      required: false,
      default: false
    },

    printView: {
      type: Boolean,
      required: false,
      default: false
    },

    label: {
      type: String,
      required: false,
      default: ''
    },

    small: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data: () => ({
    itemValue: -1,
    availableValues: [0, 1, 2],
    anchors: [
      {
        value: 0,
        translation: [
          { languageKey: 'de-DE', translation: '(Eher) Entwicklungsfeld' },
          {
            languageKey: 'en-US',
            translation:
              'Does not fully meet expectations (area of development)'
          }
        ]
      },
      {
        value: 1,
        translation: [
          { languageKey: 'de-DE', translation: 'Trifft Erwartung' },
          {
            languageKey: 'en-US',
            translation: 'Meets expectations'
          }
        ]
      },
      {
        value: 2,
        translation: [
          { languageKey: 'de-DE', translation: '(Eher) Stärke' },
          {
            languageKey: 'en-US',
            translation: 'Exceeds expectations (strength)'
          }
        ]
      }
    ]
  }),

  watch: {
    value: {
      deep: true,
      handler() {
        this.itemValue = this.value.value;
      }
    },

    itemValue() {
      this.value.value = this.itemValue;
      this.$emit('input', this.value);
      this.$emit('change');
    }
  },

  mounted() {
    if (this.value !== undefined && this.value.value !== undefined) {
      this.itemValue = this.value.value;
    }
  },

  components: {
    itemScale
  }
};
</script>
