<template>
  <div class="pa-4">
    <v-row>
      <v-col cols="12" class="ma-0 mb-4 pa-0">
        <v-alert v-if="dataTransferred" class="mb-0" type="warning" text>
          {{
            $t('feedbacks.dataTransferred', {
              type: $t(`feedbacks.processTypes.${dataTransferProcessType}`)
            })
          }}
        </v-alert>
      </v-col>
    </v-row>

    <v-row class="dark purple">
      <v-col cols="12">
        {{ $t('feedbacks.ehgCompetencies.1') }}
      </v-col>
    </v-row>
    <v-row class="purple">
      <v-col cols="12" md="4">
        {{ localize(items[0].label) }}

        <v-tooltip bottom max-width="800">
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">mdi-information</v-icon>
          </template>

          <span v-html="localize(items[0].tooltip)"></span>
        </v-tooltip>
      </v-col>
      <v-col cols="12" md="4" v-html="localize(items[0].description)"></v-col>

      <v-col cols="12" md="4" class="text-center">
        <div v-if="feedbackData && feedbackData.isComparison" class="mb-4">
          <scale-component
            :value="getComparisonItem(feedbackTypes.SELF_EVALUATION, 0)"
            small
            :readOnly="true"
            :printView="printView"
            :label="$t('feedbacks.typeOptions.1')"
          ></scale-component>
        </div>

        <div v-if="feedbackData && feedbackData.isComparison" class="mb-8">
          <scale-component
            :value="getComparisonItem(feedbackTypes.MANAGER_EVALUATION, 0)"
            small
            :readOnly="true"
            :printView="printView"
            :label="$t('feedbacks.typeOptions.2')"
          ></scale-component>
        </div>

        <div class="mb-4">
          <scale-component
            v-model="items[0]"
            :readOnly="readOnly"
            :printView="printView"
            @change="itemChanged(0)"
          ></scale-component>
        </div>
      </v-col>
    </v-row>

    <v-row class="purple">
      <v-col cols="12" md="4">
        {{ localize(items[1].label) }}

        <v-tooltip bottom max-width="800">
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">mdi-information</v-icon>
          </template>

          <span v-html="localize(items[1].tooltip)"></span>
        </v-tooltip>
      </v-col>
      <v-col cols="12" md="4" v-html="localize(items[1].description)"></v-col>

      <v-col cols="12" md="4" class="text-center">
        <div v-if="feedbackData && feedbackData.isComparison" class="mb-4">
          <scale-component
            :value="getComparisonItem(feedbackTypes.SELF_EVALUATION, 1)"
            small
            :readOnly="true"
            :printView="printView"
            :label="$t('feedbacks.typeOptions.1')"
          ></scale-component>
        </div>

        <div v-if="feedbackData && feedbackData.isComparison" class="mb-8">
          <scale-component
            :value="getComparisonItem(feedbackTypes.MANAGER_EVALUATION, 1)"
            small
            :readOnly="true"
            :printView="printView"
            :label="$t('feedbacks.typeOptions.2')"
          ></scale-component>
        </div>

        <div class="mb-4">
          <scale-component
            v-model="items[1]"
            :readOnly="readOnly"
            :printView="printView"
            @change="itemChanged(1)"
          ></scale-component>
        </div>
      </v-col>
    </v-row>

    <v-row class="dark orange">
      <v-col cols="12">
        {{ $t('feedbacks.ehgCompetencies.2') }}
      </v-col>
    </v-row>
    <v-row class="orange">
      <v-col cols="12" md="4">
        {{ localize(items[2].label) }}

        <v-tooltip bottom max-width="800">
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">mdi-information</v-icon>
          </template>

          <span v-html="localize(items[2].tooltip)"></span>
        </v-tooltip>
      </v-col>
      <v-col cols="12" md="4" v-html="localize(items[2].description)"></v-col>

      <v-col cols="12" md="4" class="text-center">
        <div v-if="feedbackData && feedbackData.isComparison" class="mb-4">
          <scale-component
            :value="getComparisonItem(feedbackTypes.SELF_EVALUATION, 2)"
            small
            :readOnly="true"
            :printView="printView"
            :label="$t('feedbacks.typeOptions.1')"
          ></scale-component>
        </div>

        <div v-if="feedbackData && feedbackData.isComparison" class="mb-8">
          <scale-component
            :value="getComparisonItem(feedbackTypes.MANAGER_EVALUATION, 2)"
            small
            :readOnly="true"
            :printView="printView"
            :label="$t('feedbacks.typeOptions.2')"
          ></scale-component>
        </div>

        <div class="mb-4">
          <scale-component
            v-model="items[2]"
            :readOnly="readOnly"
            :printView="printView"
            @change="itemChanged(2)"
          ></scale-component>
        </div>
      </v-col>
    </v-row>

    <v-row class="orange">
      <v-col cols="12" md="4">
        {{ localize(items[3].label) }}

        <v-tooltip bottom max-width="800">
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">mdi-information</v-icon>
          </template>

          <span v-html="localize(items[3].tooltip)"></span>
        </v-tooltip>
      </v-col>
      <v-col cols="12" md="4" v-html="localize(items[3].description)"></v-col>

      <v-col cols="12" md="4" class="text-center">
        <div v-if="feedbackData && feedbackData.isComparison" class="mb-4">
          <scale-component
            :value="getComparisonItem(feedbackTypes.SELF_EVALUATION, 3)"
            small
            :readOnly="true"
            :printView="printView"
            :label="$t('feedbacks.typeOptions.1')"
          ></scale-component>
        </div>

        <div v-if="feedbackData && feedbackData.isComparison" class="mb-8">
          <scale-component
            :value="getComparisonItem(feedbackTypes.MANAGER_EVALUATION, 3)"
            small
            :readOnly="true"
            :printView="printView"
            :label="$t('feedbacks.typeOptions.2')"
          ></scale-component>
        </div>

        <div class="mb-4">
          <scale-component
            v-model="items[3]"
            :readOnly="readOnly"
            :printView="printView"
            @change="itemChanged(3)"
          ></scale-component>
        </div>
      </v-col>
    </v-row>

    <v-row class="dark pink">
      <v-col cols="12">
        {{ $t('feedbacks.ehgCompetencies.3') }}
      </v-col>
    </v-row>
    <v-row class="pink">
      <v-col cols="12" md="4">
        {{ localize(items[4].label) }}

        <v-tooltip bottom max-width="800">
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">mdi-information</v-icon>
          </template>

          <span v-html="localize(items[4].tooltip)"></span>
        </v-tooltip>
      </v-col>
      <v-col cols="12" md="4" v-html="localize(items[4].description)"></v-col>

      <v-col cols="12" md="4" class="text-center">
        <div v-if="feedbackData && feedbackData.isComparison" class="mb-4">
          <scale-component
            :value="getComparisonItem(feedbackTypes.SELF_EVALUATION, 4)"
            small
            :readOnly="true"
            :printView="printView"
            :label="$t('feedbacks.typeOptions.1')"
          ></scale-component>
        </div>

        <div v-if="feedbackData && feedbackData.isComparison" class="mb-8">
          <scale-component
            :value="getComparisonItem(feedbackTypes.MANAGER_EVALUATION, 4)"
            small
            :readOnly="true"
            :printView="printView"
            :label="$t('feedbacks.typeOptions.2')"
          ></scale-component>
        </div>

        <div class="mb-4">
          <scale-component
            v-model="items[4]"
            :readOnly="readOnly"
            :printView="printView"
            @change="itemChanged(4)"
          ></scale-component>
        </div>
      </v-col>
    </v-row>

    <v-row class="dark green">
      <v-col cols="12">
        {{ $t('feedbacks.ehgCompetencies.4') }}
      </v-col>
    </v-row>
    <v-row class="green">
      <v-col cols="12" md="4">
        {{ localize(items[5].label) }}

        <v-tooltip bottom max-width="800">
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">mdi-information</v-icon>
          </template>

          <span v-html="localize(items[5].tooltip)"></span>
        </v-tooltip>
      </v-col>
      <v-col cols="12" md="4" v-html="localize(items[5].description)"></v-col>

      <v-col cols="12" md="4" class="text-center">
        <div v-if="feedbackData && feedbackData.isComparison" class="mb-4">
          <scale-component
            :value="getComparisonItem(feedbackTypes.SELF_EVALUATION, 5)"
            small
            :readOnly="true"
            :printView="printView"
            :label="$t('feedbacks.typeOptions.1')"
          ></scale-component>
        </div>

        <div v-if="feedbackData && feedbackData.isComparison" class="mb-8">
          <scale-component
            :value="getComparisonItem(feedbackTypes.MANAGER_EVALUATION, 5)"
            small
            :readOnly="true"
            :printView="printView"
            :label="$t('feedbacks.typeOptions.2')"
          ></scale-component>
        </div>

        <div class="mb-4">
          <scale-component
            v-model="items[5]"
            :readOnly="readOnly"
            :printView="printView"
            @change="itemChanged(5)"
          ></scale-component>
        </div>
      </v-col>
    </v-row>

    <v-row class="green">
      <v-col cols="12" md="4">
        {{ localize(items[6].label) }}

        <v-tooltip bottom max-width="800">
          <template v-slot:activator="{ on }">
            <v-icon v-on="on">mdi-information</v-icon>
          </template>

          <span v-html="localize(items[6].tooltip)"></span>
        </v-tooltip>
      </v-col>
      <v-col cols="12" md="4" v-html="localize(items[6].description)"></v-col>

      <v-col cols="12" md="4" class="text-center">
        <div v-if="feedbackData && feedbackData.isComparison" class="mb-4">
          <scale-component
            :value="getComparisonItem(feedbackTypes.SELF_EVALUATION, 6)"
            small
            :readOnly="true"
            :printView="printView"
            :label="$t('feedbacks.typeOptions.1')"
          ></scale-component>
        </div>

        <div v-if="feedbackData && feedbackData.isComparison" class="mb-8">
          <scale-component
            :value="getComparisonItem(feedbackTypes.MANAGER_EVALUATION, 6)"
            small
            :readOnly="true"
            :printView="printView"
            :label="$t('feedbacks.typeOptions.2')"
          ></scale-component>
        </div>

        <div class="mb-4">
          <scale-component
            v-model="items[6]"
            :readOnly="readOnly"
            :printView="printView"
            @change="itemChanged(6)"
          ></scale-component>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import scaleComponent from './scale.vue';

import { mapActions, mapState } from 'vuex';
import { feedbackEnums } from '@/enums/feedbacks.js';
import { errorCodes } from '@/enums/errorCodes.js';

export default {
  props: {
    value: {
      type: Object,
      required: true
    },

    readOnly: {
      type: Boolean,
      required: false,
      default: false
    },

    printView: {
      type: Boolean,
      required: false,
      default: false
    },

    transferredData: {
      type: Object,
      required: false,
      default: () => null
    }
  },

  data: () => ({
    dataTransferComplete: false,
    dataTransferred: false,
    dataTransferProcessType: -1,
    items: [
      {
        id: '0695dbe9-cbf2-43cb-8f17-4074920a29cb',
        value: -1,
        label: [
          { languageKey: 'de-DE', translation: 'Antrieb' },
          { languageKey: 'en-US', translation: 'Drive' }
        ],
        description: [
          {
            languageKey: 'de-DE',
            translation:
              '<ul><li>Leistungsfokus</li><li>Hochleistungskultur</li><li>Wettbewerbsgeist</li></ul>'
          },
          {
            languageKey: 'en-US',
            translation:
              '<ul><li>Achievement Focus</li><li>High-Performance Culture</li><li>Competitive Spirit</li></ul>'
          }
        ],

        tooltip: [
          {
            languageKey: 'de-DE',
            translation: `
								<strong>Leistungsfokus</strong><br />
								Treibt das Unternehmenswachstum und die Ergebnislieferung; fordert andere heraus, 
								Ziele termingenau und innerhalb des Budgets zu erreichenoder zu übertreffen; 
								ist ständig bestrebt, das Leistungsniveau zu erhöhen, indem sowohl herausfordernd als auch fair agiert wird; 
								geht angemessene und kalkulierte Risiken für das Unternehmen ein, 
								um Ergebnisse und Wachstum zu erzielen und um auf eine sich verändernde Welt zu reagieren
								<br /><br />
								<strong>Hochleistungskultur</strong><br />
								Entwickelt unsere Hochleistungskultur weiter, indem	operative Strenge und Ergebnisorientierung mit
								innovativen Arbeitsweisen in Einklang gebracht werden; gibt sich nie mit Mittelmaß zufrieden;
								hinterfragt kontinuierlich Bereiche, in denen die Leistung nicht optimal ist und nimmt gegebenenfalls
								Änderungen vor; reagiert aufgeschlossen auf geschäftliche Herausforderungen und
								Veränderungen; zeigt pragmatischen Enthusiasmus und Optimismus für das, was erreicht werden kann,
								stellt sich Herausforderungen und maximiert die vor uns liegenden Möglichkeiten; agiert mit einer
								flexiblen Denkweise
								<br /><br />
								<strong>Wettbewerbsgeist</strong><br />
								Zeigt den Willen, die externe Konkurrenz von Hays zu übertreffen und gleichzeitig Kundinnen und Kunden
								sowie Kolleginnen und Kollegen engagiert und motiviert zu halten; entwickelt bei sich selbst und
								anderen Widerstandsfähigkeit; ermutigt zur freundlichen und produktiven internen Rivalität, um
								ein positiver Katalysator für Ergebnisse zu sein, ohne zu versuchen, um jeden Preis zu gewinnen; erinnert
								sich immer daran, dass der wahre Wettbewerb außerhalb von Hays und nicht mit unseren internen
								Kolleginnen und Kollegen stattfindet.
							`
          },
          {
            languageKey: 'en-US',
            translation: `
								<strong>Achievement Focus</strong><br />
								Drives business growth and delivery of results; challenges others to achieve or exceed goals on time and within 
								budget; constantly strives to increase levels of performance by being both challenging and fair; takes appropriate 
								and calculated risks for the business in pursuit of results, growth and in response to a changing world.
								<br /><br />
								<strong>High-Performance Culture</strong><br />
								Continues to evolve our high performing culture by balancing operational rigour and drive for results 
								with innovative ways of working; never settles for mediocrity, continually challenges areas where performance 
								is sub-optimal making changes as appropriate; responds open-mindedly to business challenges and change; 
								demonstrates pragmatic enthusiasm and optimism for what can be achieved by facing into challenges and 
								maximising the opportunities in front of us; operates with a flexible mindset.
								<br /><br />
								<strong>Competitive Spirit</strong><br />
								Demonstrates a desire to outperform Hays’ external competitors, whilst keeping customers and 
								colleagues engaged and motivated; develops resilience in themselves and others; encourages 
								friendly and productive internal rivalry to be a positive catalyst for results without seeking 
								to win at all costs; remembers always that the real competition is external to Hays and not 
								with our internal colleagues. 
							`
          }
        ]
      },
      {
        id: 'e29d14dc-4396-413a-86a0-3e59bb8816c4',
        value: -1,
        label: [
          { languageKey: 'de-DE', translation: 'Umsetzung' },
          { languageKey: 'en-US', translation: 'Execution' }
        ],
        description: [
          {
            languageKey: 'de-DE',
            translation:
              '<ul><li>Strukturierung der Aktivitäten</li><li>Einsatz von Ressourcen</li><li>Kontinuierliche Verbesserung</li><li>Veränderungen herbeiführen</li></ul>'
          },
          {
            languageKey: 'en-US',
            translation:
              '<ul><li>Structuring Activities</li><li>Orchestrating Resources</li><li>Continious Improvement</li><li>Making Change Happen</li></ul>'
          }
        ],
        tooltip: [
          {
            languageKey: 'de-DE',
            translation: `
							<strong>Strukturierung der Aktivitäten</strong><br />
							Übersetzt strategische Bestrebungen in klare Ziele und Ergebnisse mit klar definierten
							Erfolgskennzahlen; plant, organisiert und strukturiert Aktivitäten, um die Mitarbeitenden in die Lage zu
							versetzen, die Ziele und Ergebnisse zu erreichen. 
							<br /><br />
							<strong>Einsatz von Ressourcen</strong><br />
							Setzt eigene und fremde Zeit, Wissen und Fähigkeiten optimal ein; delegiert die Ausführung
							von Aufgaben; setzt die richtigen Ressourcen auf den richtigen Hierarchiestufen für die richtigen
							Aktivitäten ein; stellt sicher, dass alle Mitarbeitenden an der richtigen Stelle eingesetzt werden; stellt
							sicher, dass alle mit Konsistenz und Genauigkeit arbeiten, spricht suboptimale Leistungen effektiv an;
							widersteht der Versuchung, Aktivitäten zu verfolgen, die von unerfahreneren Mitarbeitenden
							übernommen werden sollten; versteht, wann und was man „loslassen" muss, um den besten Ertrag zu
							erzielen.
							<br /><br />
							<strong>Kontinuierliche Verbesserung</strong><br />							
							Strebt stetig nach Verbesserungen und Effizienz,
							steigert Effektivität; nimmt eine „Software-/agile
							Denkweise“ an, um mit neuen Ideen zu
							experimentieren und zu lernen; findet neue
							Geschäftsmöglichkeiten, indem agil auf den Markt
							reagiert wird; nimmt sich Zeit für die Reflexion und
							Analyse relevanter Daten und Erkenntnisse, um
							Zukunftspläne zu durchdringen und unsere Kunden
							zu bedienen.
							<br /><br />
							<strong>Veränderungen herbeiführen</strong><br />
							Agiert flexibel und aufgeschlossen, nimmt
							Veränderungen an, anstatt sie zu verleugnen; zeigt
							die Bereitschaft, die Richtung und den Ansatz zu
							ändern, wenn dies erforderlich ist; nutzt die Hays
							Change Methodology, um eine effektive und
							konsistente Umsetzung von Veränderungen zu
							gewährleisten; stellt den effektiven Abschluss von
							Initiativen sicher und evaluiert deren Erfolg, wobei
							die gewonnenen Erkenntnisse auf nachfolgende
							Initiativen anwendet werden; nutzt „Power Skills“
							(Soft Skills), um sicherzustellen, dass die
							Mitarbeitenden die Veränderungen annehmen und
							unterstützen.
						`
          },

          {
            languageKey: 'en-US',
            translation: `
							<strong>Structuring Activities</strong><br />
							Translates strategic aims into clear 
							goals and outcomes with clearly 
							defined success metrics; plans, 
							organises and structures activities to 
							enable their people to deliver these 
							goals and outcomes.
							<br /><br />
							<strong>Orchestrating Resources</strong><br />
							Deploys own and others' time, 
							knowledge and capabilities to best 
							effect; delegates the execution of 
							tasks; applies the correct resources 
							at the right level of seniority to the 
							right activities; ensures everyone is 
							working with consistency and rigour 
							and effectively addresses sub-optimal 
							performance; resists the temptation 
							to be pulled down into activities that 
							should be undertaken by more junior 
							colleagues; understands when and 
							what to 'let go of' to achieve the 
							best return.
							<br /><br />
							<strong>Continuous Improvement</strong><br />
							Always looks to make improvements 
							and drive efficiency and effectiveness; 
							adopts a 'software/agile mindset'
							to experiment with new ideas and 
							addresses key learns; finds new 
							business opportunities by responding 
							with agility to the market; takes time 
							to reflect on and analyse relevant data 
							and insights to more accurately inform 
							future plans and serve our customers. 
							<br /><br />
							<strong>Making Change Happen</strong><br />
							Operates with flexibility and open-mindedness, embracing change 
							rather than living in denial of it; shows 
							willingness to change direction and 
							approach when required; utilises 
							the Hays Change Methodology to 
							ensure effective and consistent 
							implementation of change; ensures 
							the effective completion of initiatives 
							and evaluates their success, applying 
							any learning to subsequent initiatives; 
							utilises 'power skills' (soft skills) to 
							ensure their people embrace, adopt 
							and sustain the change.
						`
          }
        ]
      },
      {
        id: '82cdee6f-afab-485b-bdf1-e688726dd16a',
        value: -1,
        label: [
          { languageKey: 'de-DE', translation: 'Ausrichtung' },
          { languageKey: 'en-US', translation: 'Direction' }
        ],
        description: [
          {
            languageKey: 'de-DE',
            translation:
              '<ul><li>Externe Wahrnehmung</li><li>Strategische Innovation</li><li>Strategische Ausrichtung</li></ul>'
          },

          {
            languageKey: 'en-US',
            translation:
              '<ul><li>External Awareness</li><li>Strategic Innovation</li><li>Strategic Direction</li></ul>'
          }
        ],
        tooltip: [
          {
            languageKey: 'de-DE',
            translation: `
							<strong>Externe Wahrnehmung</strong><br />
							Nimmt eine breite Makroperspektive auf die
							Welt ein, die über unsere aktuellen und
							traditionellen Arbeitsweisen hinausgeht;
							antizipiert die sich verändernde Arbeitswelt,
							anstatt einfach nur darauf zu reagieren, um
							sicherzustellen, dass Hays ein relevantes,
							fokussiertes und führendes Recruiting-
							Geschäftsmodell hat.

							<br /><br />
							<strong>Strategische Innovation</strong><br />
							Zeigt eine starke und dauerhafte Neugier, die
							die Entdeckung neuer Ideen ermöglicht; ist
							offen für Chancen und Veränderungen, auch
							wenn dies bedeutet, sich aus bestehenden
							Märkten zu verabschieden; versteht den lokalen
							Markt von Hays und seine sich verändernde
							Dynamik; findet neue Geschäftsmöglichkeiten,
							indem auf innovative und kreative Weise über
							unser Geschäft nachdacht wird; analysiert
							Daten, Ideen und Möglichkeiten, um deren
							Relevanz und Wert für Hays im Einklang mit
							unserer Hays Story zu verstehen.

							<br /><br />
							<strong>Strategische Ausrichtung</strong><br />
							Entwickelt und kommuniziert auf der Grundlage
							einer validen Analyse relevanter quantitativer
							und qualitativer Daten einen klaren Fokus und
							eine Vision für die Zukunft; stellt sicher, dass die
							organisationalen Wachstumsstrategien und die
							Richtung und Entwicklung des Unternehmens
							mit unserer Hays Story abgestimmt sind.

						`
          },

          {
            languageKey: 'en-US',
            translation: `
							<strong>External Awareness</strong><br />
							Takes a broad macro perspective 
							on the world beyond our current 
							and traditional ways of operating; 
							anticipates rather than simply reacting 
							to the changing world of work to 
							ensure Hays has a relevant, focused 
							and leading recruitment business.

							<br /><br />
							<strong>Strategic Innovation</strong><br />
							Demonstrates a strong and lasting 
							curiosity that enables the discovery 
							of new ideas; is open-minded to 
							opportunities and change, even if 
							this means exiting from existing 
							markets; is insightful about Hays’ 
							local marketplace and its changing 
							dynamics; finds new business 
							opportunities by thinking about our 
							business in innovative and creative 
							ways; analyses data, ideas and 
							opportunities to understand their 
							relevance and value for Hays in 
							keeping with Our Hays Story. 

							<br /><br />
							<strong>Strategic Direction</strong><br />
							Based on valid analysis of relevant 
							quantitative and qualitative data, 
							develops and communicates a clear 
							focus and vision for the future; ensures 
							organisational growth strategies and 
							the direction and evolution of the 
							business is aligned with Our Hays Story.

						`
          }
        ]
      },
      {
        id: '2cc09fa9-7afe-4752-8ebf-6a7a83693910',
        value: -1,
        label: [
          { languageKey: 'de-DE', translation: 'Strategische Beziehungen' },
          { languageKey: 'en-US', translation: 'Strategic Relationships' }
        ],
        description: [
          {
            languageKey: 'de-DE',
            translation:
              '<ul><li>Kundenorientierung</li><li>Strategische Beziehungen</li><li>Zusammenarbeit</li></ul>'
          },

          {
            languageKey: 'en-US',
            translation:
              '<ul><li>Customer Centric</li><li>Strategic Relationships</li><li>Collaboration</li></ul>'
          }
        ],
        tooltip: [
          {
            languageKey: 'de-DE',
            translation: `
							<strong>Kundenorientierung</strong><br />
							Lebt Kundenorientierung und ist stets bestrebt,
							die sich verändernden Bedürfnisse der
							Kundinnen und Kunden zu verstehen; sucht aktiv
							nach relevanten Daten, Erkenntnissen und
							Feedbacks um sicherzustellen, dass wir unseren
							Service aus der Sicht unserer Kundinnen und
							Kunden sehen; nutzt diese Erkenntnisse, um zu
							hinterfragen, wie wir unseren Service
							bereitstellen und anpassen können; feiert und
							belohnt kundenorientierte Einstellung und
							Verhalten.

							<br /><br />
							<strong>Strategische Beziehungen</strong><br />
							Baut starke interne und externe Beziehungen
							auf und versteht ihren Wert; tritt als
							Botschafterin bzw. Botschafter der Marke auf.
							
							<br /><br />
							<strong>Zusammenarbeit</strong><br />
							Baut eine Kultur und Form der Zusammenarbeit
							auf, die Kollaboration und Vernetzung
							ermöglicht; denkt gesamtunternehmerisch,
							schätzt Vielfalt und Inklusion und ermutigt zu
							einer breiten Perspektive; kollaboriert mit dem
							Willen zu lernen und teilhaben zu lassen;
							hinterfragt kritisch konkurrierende „Silos“, die
							den Austausch von Best Practices verhindern,
							behält im Gedächtnis, dass die wirkliche
							Konkurrenz außerhalb von Hays ist.
						`
          },

          {
            languageKey: 'en-US',
            translation: `
							<strong>Customer Centric</strong><br />
							Role models customer centricity by 
							working to understand our customers’ 
							changing needs; actively seeks out 
							relevant data, insights and feedback to 
							ensure we are looking at our services 
							through the eyes of our customers; 
							uses these insights to question how 
							we deliver and adapt our services; 
							celebrates and rewards customer 
							centric mindset and behaviours.

							<br /><br />
							<strong>Strategic Relationships</strong><br />
							Understands the value of, and 
							establishes, strong internal and 
							external relationships; acts as an 
							ambassador for the brand. 

							<br /><br />
							<strong>Collaboration</strong><br />
							Builds a culture and ways of 
							working that enable and encourage 
							collaboration and connection; thinks 
							across the business, valuing diversity 
							and inclusivity and encouraging a 
							broad perspective; collaborates with 
							a willingness to learn and share; 
							challenges competitive silos that 
							prevent sharing of best practice, 
							keeping in mind that the real 
							competition is external to Hays.
						`
          }
        ]
      },
      {
        id: 'e59ebaf5-99b2-45e3-93c0-49f430af828a',
        value: -1,
        label: [
          {
            languageKey: 'de-DE',
            translation: 'Entwicklung eigener Fähigkeiten'
          },
          {
            languageKey: 'en-US',
            translation: 'Developing own capabilities'
          }
        ],
        description: [
          {
            languageKey: 'de-DE',
            translation:
              '<ul><li>Integrität</li><li>Selbstkenntnis</li><li>Stetige persönliche Weiterentwicklung</li><li>Mut</li><li>Eigenmotivation</li></ul>'
          },

          {
            languageKey: 'en-US',
            translation:
              '<ul><li>Integrity</li><li>Knowing Self</li><li>Continuous Personal Development</li><li>Courage</li><li>Self-motivation</li></ul>'
          }
        ],
        tooltip: [
          {
            languageKey: 'de-DE',
            translation: `
							<strong>Integrität</strong><br />							
							Handelt jederzeit mit der Absicht, für Hays „das
							Richtige zu tun“; handelt mit Integrität, Ehrlichkeit,
							Aufrichtigkeit und Fairness; verhält sich so, dass das
							Vertrauen anderer gewonnen wird; überzeugt andere
							davon, dass es wichtig ist, „das Richtige zu tun“,
							anstatt ihnen nur zu sagen, dass sie sich fügen sollen;
							beteiligt sich an Diskussionen über
							Gleichberechtigung, Vielfalt und Integration (ED&I -
							Equality, Diversity & Inclusion) mit
							Einfühlungsvermögen, Offenheit und der Bereitschaft
							zu lernen und zu verstehen; unterstützt aktiv alle
							organisationalen ED&I-Initiativen.

							<br /><br />
							<strong>Selbstkenntnis</strong><br />
							Entwickelt kontinuierlich ein Bewusstsein für eigene
							persönliche Werte, Stärken, Vorurteile und
							Entwicklungsfelder; nutzt diese Selbsterkenntnis, um
							das eigene Führungsverhalten zu entwickeln und
							erzeugt dadurch Vertrauen und Loyalität.

							<br /><br />
							<strong>Stetige persönliche Weiterentwicklung</strong><br />
							Engagiert sich für die eigene Weiterentwicklung und
							akzeptiert dabei, dass es immer noch mehr zu lernen
							gibt; ist offen für neue Ideen und Erfahrungen; holt
							aktiv Feedback ein, reflektiert und passt
							Herangehensweisen bei Bedarf an; sieht
							Herausforderungen und Rückschläge als Chance zum
							Lernen; fungiert als Vorbild für andere.

							<br /><br />
							<strong>Mut</strong><br />
							Zeigt die Bereitschaft, sich schwierigen Situationen zu
							stellen; besteht gegen Widerstände durch den Einsatz
							von fundiertem Urteilsvermögen und
							Einflussmöglichkeiten; hat den Mut, Probleme zu
							lösen, indem die beste Option für die Organisation
							gewählt wird, auch wenn dies bedeutet, den Status
							quo in Frage zu stellen; spricht herausfordernde
							zwischenmenschliche Themen konstruktiv und mit
							Respekt an; verlässt die eigene Komfortzone, um das
							eigene Führungsverhalten und die eigenen
							Führungsfähigkeiten weiterzuentwickeln, auch wenn
							sich das riskant anfühlt.
							
							<br /><br />
							<strong>Eigenmotivation</strong><br />
							Hält Selbstmotivation und Belastbarkeit aufrecht, die
							für den Umgang mit den Herausforderungen einer
							leitenden Funktion notwendig sind; zeigt eine gesunde
							Balance aus Entschlossenheit, Tatkraft und Ehrgeiz für
							sich selbst und das Unternehmen, ohne sich selbst und
							andere so sehr unter Druck zu setzen, dass es zum
							Burnout führt.
						`
          },

          {
            languageKey: 'en-US',
            translation: `
							<strong>Integrity</strong><br />
							At all times operates with the 
							intention of ‘doing the right thing’ 
							for Hays; acts with integrity, honesty, 
							genuineness and fairness; behaves in 
							a way that earns others’ trust; finds 
							ways of making doing the right thing 
							matter to people, rather than just 
							telling them to comply; engages in 
							discussions about equality, diversity 
							and inclusion (ED&I) with empathy, 
							openness and a willingness to learn 
							and understand; actively supports all 
							ED&I organisational initiatives. 

							<br /><br />
							<strong>Knowing Self</strong><br />
							Continuously develops awareness of 
							own personal values, strengths, biases 
							and areas needing development; uses 
							this self-awareness to develop their 
							own leadership practice that creates 
							trust and followership.

							<br /><br />
							<strong>Continuous Personal Development</strong><br />
							Is committed to their own ongoing 
							development, accepting there is 
							always more to learn; is open to new 
							ideas and experiences; actively seeks 
							feedback, reflects on it and adapts 
							approach as required; sees challenges 
							and setbacks as an opportunity to 
							learn; acts as a role models for others.
							
							<br /><br />
							<strong>Courage</strong><br />
							Demonstrates a willingness to confront 
							difficult situations; perseveres in 
							the face of resistance using sound 
							judgement and influence; has the 
							courage to solve problems by choosing 
							the best option for the organisation 
							even if this means challenging the 
							status quo; addresses challenging 
							interpersonal issues constructively 
							and with respect; goes beyond 
							their comfort zone to develop their 
							leadership behaviours and capability, 
							however risky that might feel.

							<br /><br />
							<strong>Self-motivation</strong><br />
							Maintains the self-motivation and 
							resilience to deal with the challenges 
							of a senior role; shows a healthy and 
							balanced determination, drive and 
							ambition for themselves and the 
							business, without pushing oneself and 
							others so hard it causes burn out
						`
          }
        ]
      },
      {
        id: '6b87d96f-9f51-4ee6-9348-be9028eee91e',
        value: -1,
        label: [
          {
            languageKey: 'de-DE',
            translation: 'Das Beste aus Anderen herausholen'
          },
          {
            languageKey: 'en-US',
            translation: 'Getting the best from others'
          }
        ],
        description: [
          {
            languageKey: 'de-DE',
            translation:
              '<ul><li>Beziehungen</li><li>Emotionale Intelligenz</li><li>Situative Flexibilität</li><li>Inspiration und Tatkraft</li><li>Gemeinsame Ausrichtung</li><li>Entscheidungen treffen</li></ul>'
          },

          {
            languageKey: 'en-US',
            translation:
              '<ul><li>Relationships</li><li>Emotional Intelligence</li><li>Situational Flexibility</li><li>Inspirational and Energetic</li><li>Creating Alignment</li><li>Decision Making</li></ul>'
          }
        ],
        tooltip: [
          {
            languageKey: 'de-DE',
            translation: `
							<strong>Beziehungen</strong><br />
							Legt großen Wert auf den Aufbau starker
							Arbeitsbeziehungen, die auf dem Respekt für
							individuelle Unterschiede beruhen; baut Verbindung und
							Vertrauen auf, indem aktiv Präsenz bei Kolleginnen und
							Kollegen gezeigt wird, anderen zugehört wird, um sie zu
							verstehen, und ihnen der Freiraum und das Zutrauen
							gegeben wird, konstruktiv zu hinterfragen; lebt ein
							angemessenes Maß an Ehrlichkeit und Verletzlichkeit in
							der Kommunikation vor.

							<br /><br />
							<strong>Emotionale Intelligenz</strong><br />
							Zeigt ein Bewusstsein für die eigenen Emotionen und
							steuert diese mit Selbstkontrolle; erkennt die Emotionen
							anderer korrekt und passt die Kommunikation
							entsprechend an; versteht, dass Empathie und Mitgefühl
							zur Motivation und zum Engagement der
							Mitarbeitenden beitragen und arbeitet stark daran, diese
							Verhaltensweisen zu zeigen.

							<br /><br />
							<strong>Situative Flexibilität</strong><br />
							Zeigt die Bereitschaft, den eigenen Führungsstil flexibel
							zu gestalten; wählt einen Führungsstil, der je nach
							Person und Situation angemessen ist; wählt einen Win-
							Win-Ansatz (keinen Win-Lose- oder Lose-Lose- oder
							„one size fits all“-Ansatz); ist ein Vorbild für andere
							durch flexibles Handeln und Anpassen an sich ändernde
							Situationen.

							<br /><br />
							<strong>Inspiration und Tatkraft</strong><br />
							Verdient sich Vertrauen und Respekt, was Menschen
							dazu inspiriert, zu folgen; demonstriert Begeisterung
							und Zuversicht, die durch Kompetenz und Seriosität
							unterstützt werden; erkennt und würdigt
							Verhaltensweisen, die einen positiven Beitrag zu unserer
							Kultur und unseren Zielen liefern; inspiriert andere,
							aufrichtig zuzuhören, um zu verstehen; kommuniziert
							klar und prägnant; spricht sowohl die Herzen als auch die
							Köpfe an.

							<br /><br />
							<strong>Gemeinsame Ausrichtung</strong><br />
							Erzeugt eine gemeinsame Ausrichtung durch eine
							überzeugende Vision für die Entwicklung von Hays und
							bricht unser Ziel, unsere Werte und unsere Strategie auf
							die lokale Ebene herunter.

							<br /><br />
							<strong>Gemeinsame Ausrichtung</strong><br />
							Sucht nach relevanten Daten und Erkenntnissen; reagiert
							effektiv auf Situationen, auch bei hoher Komplexität,
							Mehrdeutigkeit und unter Druck; regt selbstbewusst
							Diskussionen an, und ermutigt offen zur
							Meinungsvielfalt; übernimmt Verantwortung für die
							eigenen Entscheidungen.
						`
          },

          {
            languageKey: 'en-US',
            translation: `
							<strong>Relationships</strong><br />
							Places a high value on building strong 
							working relationships grounded in a 
							respect for individual differences; builds 
							rapport and trust by being actively 
							present with colleagues, listening 
							to them to understand and giving 
							them the freedom and confidence to 
							constructively challenge; role models 
							appropriate levels of honesty and 
							vulnerability in communications. 

							<br /><br />
							<strong>Emotional Intelligence</strong><br />
							Demonstrates an awareness of one’s 
							own emotions and manages them with 
							self-control; accurately reads others’ 
							emotions and appropriately adjusts 
							their communication; understands 
							that empathy and compassion 
							contribute to employee motivation 
							and engagement and works hard to 
							demonstrate these behaviours. 

							<br /><br />
							<strong>Situational Flexibility</strong><br />
							Demonstrates a willingness to flex 
							their leadership style; chooses a style 
							that is appropriate for the person 
							and situation; takes a win-win (not 
							win-lose or lose-lose or ‘one size fits 
							all’) approach; models the way for 
							others through operating flexibly and 
							adapting to changing situations.

							<br /><br />
							<strong>Inspirational and Energetic</strong><br />
							Earns trust and respect, which inspires 
							people to follow them; demonstrates 
							an enthusiasm and confidence that is 
							supported by competence and gravitas; 
							recognises and celebrates behaviours 
							which contribute positively to our 
							culture and objectives; inspires others 
							by genuinely listening to understand; 
							communicates clearly and concisely; 
							captures hearts as well as minds.

							<br /><br />
							<strong>Creating Alignment</strong><br />
							Establishes alignment by articulating 
							a compelling vision for Hays’ ongoing 
							evolution, making our purpose, values, 
							and strategy real at a local level.

							<br /><br />
							<strong>Decision Making</strong><br />
							Seeks relevant data and insights; 
							responds effectively to situations, 
							including when faced with high levels of 
							complexity, ambiguity and when under 
							pressure; confidently creates debate, 
							and openly encourages diversity of 
							opinion; takes accountability for their 
							decision making.
						`
          }
        ]
      },
      {
        id: 'd044e1cd-9236-44c3-a353-ebc5e796d727',
        value: -1,
        label: [
          {
            languageKey: 'de-DE',
            translation: 'Entwicklung der Fähigkeiten Anderer'
          },

          {
            languageKey: 'en-US',
            translation: "Developing others' capabilities"
          }
        ],
        description: [
          {
            languageKey: 'de-DE',
            translation:
              '<ul><li>Entwicklung Anderer</li><li>Lernkultur</li><li>Teamentwicklung</li></ul>'
          },

          {
            languageKey: 'en-US',
            translation:
              '<ul><li>Developing Others</li><li>Learning Culture</li><li>Team Development</li></ul>'
          }
        ],
        tooltip: [
          {
            languageKey: 'de-DE',
            translation: `
							<strong>Entwicklung Anderer</strong><br />
							Ermutigt Menschen, die beste Version ihrer Selbst zu
							sein und ihr volles Potenzial auszuschöpfen, indem ihr
							berufliches Wachstum aktiv unterstützt wird; sammelt
							nützliche quantitative und qualitative Informationen, um
							die Entwicklung zu unterstützen; arbeitet mit anderen
							zusammen, um kurz-, mittel- und längerfristige
							Entwicklungsziele zu identifizieren; agiert als „Thinking
							Partner“, um bei der Entwicklung von kritischem Denken
							und Problemlösungsfähigkeiten zu helfen; schafft ein
							angemessenes Gleichgewicht aus der Entwicklung von
							Führungs- und Managementkompetenzen und
							technischen Fähigkeiten; lebt vor, wie man andere in
							ihrer Entwicklung fördert und stellt sicher, dass
							Mitarbeitende diese Art der Entwicklung in ihren
							Einheiten weiter vorantreiben.

							<br /><br />
							<strong>Lernkultur</strong><br />
							Zeigt Mitarbeitenden, dass die Bedeutung einer
							kontinuierlichen beruflichen Entwicklung geschätzt wird;
							gibt den Mitarbeitenden explizit die Erlaubnis, Zeit in
							ihre persönliche Entwicklung zu investieren.

							<br /><br />
							<strong>Teamentwicklung</strong><br />
							Entwickelt Hochleistungsteams, die in einem
							gemeinsamen Ziel vereint sind und gut
							zusammenarbeiten, um diese zu erreichen; versteht und
							akzeptiert, dass das eigene Führungsverhalten der
							wichtigste Einflussfaktor für die Teamkultur ist; arbeitet
							daran, eine vertrauensvolle Teamumgebung zu schaffen,
							durch folgende Verhaltensweisen: agiert offen und
							inklusiv; gibt Einzelpersonen die Möglichkeit, ihre
							Meinung zu äußern; fördert das offene Geben und
							Empfangen von konstruktivem Feedback; verdeutlicht
							die Erwartung einer gemeinsamen Verantwortlichkeit für
							den Erfolg des Unternehmens; erwartet von den
							Teammitgliedern, dass sie sich gegenseitig aktiv
							unterstützen.
						`
          },

          {
            languageKey: 'en-US',
            translation: `
							<strong>Developing Others</strong><br />
							Encourages people to be the best 
							version of themselves and achieve 
							their full potential by actively 
							supporting their professional growth; 
							gathers useful quantitative and 
							qualitative information to inform 
							development; works with them to 
							identify short, medium and longer-term development goals; acts as a 
							Thinking Partner to help develop 
							critical thinking and problem-solving 
							abilities; strikes an appropriate 
							balance between the development 
							of leadership and management 
							competence with technical capability; 
							role models the behaviours that 
							develop others and ensures reports 
							drive this development throughout 
							their business.
							
							<br /><br />
							<strong>Learning Culture</strong><br />
							Shows their people they value the 
							importance of continuous professional 
							development; explicitly gives 
							permission for people to spend time 
							on their personal development.

							<br /><br />
							<strong>Team Development</strong><br />
							Develops high-performing teams 
							that are united in a common purpose 
							and work together well to achieve it; 
							understands and accepts that their 
							own leadership behaviour is the most 
							significant variable that shapes that 
							team’s culture; works to create a high 
							trust team environment by: operating 
							openly and inclusively; enabling 
							individuals to voice their opinions; 
							encouraging the open giving and 
							receiving of constructive feedback; 
							setting an expectation of shared 
							accountability for the success of the 
							business; expecting team members to 
							actively support one another.
						`
          }
        ]
      }
    ],

    feedbackTypes: feedbackEnums.feedbackTypes
  }),

  computed: {
    ...mapState('feedbacks', {
      ehgValues: 'ehgValues',
      feedbackData: 'currentFeedbackData'
    }),

    storeData() {
      if (
        this.ehgValues !== null &&
        this.ehgValues !== undefined &&
        this.ehgValues[this.$route.params.id]
      ) {
        return this.ehgValues[this.$route.params.id];
      }

      return [];
    }
  },

  watch: {
    transferredData() {
      const hasEhgValues =
        this.storeData.filter((x) => x.value > -1).length > 0;

      if (
        !hasEhgValues &&
        this.transferredData !== null &&
        this.transferredData.matrixElementData &&
        this.transferredData.matrixElementData !== '[]'
      ) {
        this.setTransferredValue();
      }
    }
  },

  mounted() {
    for (let item of this.storeData) {
      const dataItem = this.items.filter((x) => x.id === item.id);
      if (dataItem.length > 0) {
        dataItem[0].value = item.value;
      }
    }
  },

  methods: {
    ...mapActions({
      saveItem: 'feedbacks/saveMatrixItemValue'
    }),

    getComparisonItem(feedbackTypeId, itemIndex) {
      const typeItems = this.feedbackData.comparisonData.filter(
        (x) => x.feedbackType === feedbackTypeId
      );

      if (typeItems.length === 0) {
        return -1;
      }

      const itemId = this.items[itemIndex].id;

      const matchingItem = typeItems[0].values.filter(
        (x) => x.matrixItemId === itemId
      );

      let val = -1;
      if (matchingItem.length > 0) {
        val = matchingItem[0].value;
      }

      return {
        value: val
      };
    },

    async itemChanged(itemIndex) {
      var requestData = {
        feedbackId: this.$route.params.id,
        elementId: this.value.id,
        itemId: this.items[itemIndex].id,
        value: this.items[itemIndex].value
      };

      var result = await this.saveItem(requestData);

      if (
        result.errorCode &&
        result.errorCode === errorCodes.errorCodes.UNEXPECTED_EXCEPTION
      ) {
        this.$emit('errorOnSave');
      }
    },

    setTransferredValue() {
      this.dataTransferred = true;

      const items = JSON.parse(this.transferredData.matrixElementData);
      for (let item of items) {
        const dataItem = this.items.filter((x) => x.id === item.MatrixItemId);
        if (dataItem.length === 0) {
          continue;
        }

        if (dataItem[0].value < 0) {
          dataItem[0].value = item.Value;
        }
      }

      this.dataTransferProcessType = this.transferredData.processType;
      this.dataTransferComplete = true;
    }
  },

  components: {
    scaleComponent
  }
};
</script>

<style lang="scss" scoped>
@import '~vuetify/src/styles/settings/_variables';

.text-center {
  width: 500px;
  min-width: 500px;
}

.print-view .text-center {
  width: auto;
  min-width: auto;
}

.table {
  position: relative;
  table-layout: fixed;
  width: 100%;

  td {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
}

.row.purple {
  background-color: var(--v-background-ehg) !important;
  color: #80379b;

  & > div {
    border-bottom: 1px dashed var(--v-background-purple);
  }

  & > div:first-of-type {
    border-left: 1px solid var(--v-background-purple);
  }

  & > div:last-of-type {
    border-right: 1px solid var(--v-background-purple);
  }
}

.row.dark.purple {
  background-color: var(--v-background-purple) !important;
}

.row.orange {
  background-color: var(--v-background-ehg) !important;
  color: #e98300;

  & > div {
    border-bottom: 1px dashed var(--v-background-orange);
  }

  & > div:first-of-type {
    border-left: 1px solid var(--v-background-orange);
  }

  & > div:last-of-type {
    border-right: 1px solid var(--v-background-orange);
  }
}

.row.dark.orange {
  background-color: var(--v-background-orange) !important;
}

.row.pink {
  background-color: var(--v-background-ehg) !important;
  color: #d72085;

  & > div {
    border-bottom: 1px dashed var(--v-background-pink);
  }

  & > div:first-of-type {
    border-left: 1px solid var(--v-background-pink);
  }

  & > div:last-of-type {
    border-right: 1px solid var(--v-background-pink);
  }
}

.row.dark.pink {
  background-color: var(--v-background-pink) !important;
}

.row.green {
  background-color: var(--v-background-ehg) !important;
  color: #739600;

  & > div {
    border-bottom: 1px dashed var(--v-background-green);
  }

  & > div:first-of-type {
    border-left: 1px solid var(--v-background-green);
  }

  & > div:last-of-type {
    border-right: 1px solid var(--v-background-green);
  }
}

.row.green:last-of-type > td {
  border-bottom: 1px solid var(--v-background-green);
}

.row.dark.green {
  background-color: var(--v-background-green) !important;
}

.row.dark td {
  border-bottom: none;
  text-transform: uppercase;
}

.row.no-border-bottom > td {
  border-bottom: none;
}

.row > div.item-name {
  word-break: break-all;
  overflow: hidden;
}

@media #{map-get($display-breakpoints, 'sm-and-down')} {
  .row.purple > div,
  .row.orange > div,
  .row.pink > div,
  .row.green > div {
    border-bottom: none;
  }

  .row.purple > div:last-of-type {
    border-bottom: 1px solid #e8d3ef;
  }

  .row.orange > div:last-of-type {
    border-bottom: 1px solid #ffe7c8;
  }

  .row.pink > div:last-of-type {
    border-bottom: 1px solid #f8d1e7;
  }

  .row.green > div:last-of-type {
    border-bottom: 1px solid #ddeab4;
  }
}

.print-view .questionnaire-element-wrapper .row > div {
  flex: 1;
}
</style>
